import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Avatar,
  Button,
  Card,
  Col,
  Form,
  Input,
  notification,
  PageHeader,
  Popconfirm,
  Row,
  Select,
  Space,
  Switch,
  Table,
  Tag,
} from "antd";
import { useForm } from "antd/lib/form/Form";
import { ColumnsType } from "antd/lib/table";
import moment from "moment/moment";
import React, { FC, Fragment, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { Pagination } from "../../components/pagination";
import { request } from "../../utils/request";
import { ICity } from "../pages-manage";

export const Activity: FC = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({
    data: [],
    pagination: {
      count: 0,
      limit: 0,
      offset: 0,
    },
  });
  const [creation_form] = useForm();
  const [filter_form] = useForm();
  const [searchParams, setSearchParams] = useSearchParams();
  const [filter, setFilter] = useState<any>({});
  const page = searchParams.has("page") ? Number(searchParams.get("page")) : 1;
  const [cities, setCities] = useState<ICity[]>([]);

  const getData = async () => {
    setLoading(true);
    const response = await request.post("/activity/get", { ...filter, offset: 50 * (page - 1) });
    setData(response.data);
    setLoading(false);
  };

  const add = async (data: any) => {
    try {
      await request.post("/activity/create", {
        profileId: Number(data.profileId),
        always_online: data.always_online,
        enabled: Boolean(data.enabled),
      });

      await getData();

      notification.success({
        message: "Успешно сохранено",
        description: "Давайте радоваться",
      });

      creation_form.resetFields();
    } catch (err) {
      console.error(err);
    }
  };

  const edit = async ({ id, enabled, always_online }: Record<string, string | boolean>) => {
    await request.post("/activity/update", {
      id,
      enabled,
      always_online,
    });

    await getData();

    notification.success({
      message: "Успешно сохранено",
      description: "Давайте радоваться",
    });
  };

  const remove = async (id: number) => {
    await request.post("/activity/delete", { id });
    await getData();
  };

  useEffect(() => {
    getData();
  }, [page, filter]);

  const getCities = async (id?: number) => {
    const response = await request.get("/geo/cities", {
      params: {
        countryId: id ?? 1,
      },
    });
    setCities(response.data);
  };

  useEffect(() => {
    getCities();
  }, []);

  const onValuesChange = async () => {
    setSearchParams({ page: "1" });
    const values = filter_form.getFieldsValue();
    setFilter(values);
  };

  const columns: ColumnsType<any> = [
    {
      title: "ID",
      dataIndex: "id",
    },
    {
      title: "Профиль",
      dataIndex: "profileId",
      render: (_, { profile }) => {
        return (
          <a href={`/profile/${profile.id}`} rel="noreferrer">
            <Space>
              <Avatar
                src={
                  profile?.avatar
                    ? `https://api.amorus.ru${profile.avatar.image}`
                    : "https://amorus.ru/assets/images/stub_male.png"
                }
              />
              <span>{profile.name}</span>
            </Space>
          </a>
        );
      },
    },
    {
      title: "ID профиля",
      dataIndex: "profileId",
      render: (_, { profile }) => profile.id,
    },
    {
      title: "Фейк",
      dataIndex: "profile",
      render: (profile) => (profile.source ? <Tag color="red">Да</Tag> : <Tag color="green">Нет</Tag>),
    },
    {
      title: "Город",
      dataIndex: "city",
      render: (_, { profile }) => profile.city.name,
    },
    {
      title: "Онлайн",
      dataIndex: "online",
      render: (_, { profile }) => (profile.online ? <Tag color="green">Да</Tag> : <Tag color="red">Нет</Tag>),
    },
    {
      title: "Дата онлайна",
      dataIndex: "online_date",
      render: (_, { profile }) => moment(profile.online_date).format("DD.MM.YYYY HH:mm"),
    },

    {
      title: "Дата создания",
      dataIndex: "createdAt",
      render: (_, data) => moment(data.createdAt).format("DD.MM.YYYY HH:mm"),
    },
    {
      title: "Включено",
      dataIndex: "published",
      render: (_, { id, enabled }) => (
        <Fragment>
          {enabled ? (
            <Button onClick={() => edit({ id, enabled: false })} size="small" type="primary">
              Да
            </Button>
          ) : (
            <Button onClick={() => edit({ id, enabled: true })} size="small" danger={true}>
              Нет
            </Button>
          )}
        </Fragment>
      ),
    },
    {
      title: "Всегда онлайн",
      dataIndex: "always_online",
      render: (always_online, { id }) => (
        <Fragment>
          {always_online ? (
            <Button onClick={() => edit({ id, always_online: false })} size="small" type="primary">
              Да
            </Button>
          ) : (
            <Button onClick={() => edit({ id, always_online: true })} size="small" danger={true}>
              Нет
            </Button>
          )}
        </Fragment>
      ),
    },
    {
      title: "Управление",
      render: (_, { id }) => (
        <Space>
          <Popconfirm placement="leftTop" title="Удалить?" onConfirm={() => remove(id)} okText="Да" cancelText="Нет">
            <Button size="small" danger={true} icon={<DeleteOutlined />}>
              Удалить
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <div>
      <PageHeader ghost={false} title="Активности" />
      <Card style={{ marginBottom: 30 }}>
        <Form
          form={creation_form}
          layout="inline"
          onFinish={add}
          size="middle"
          initialValues={{ always_online: false, enabled: true }}
        >
          <Space size={20}>
            <Form.Item rules={[{ required: true, message: "Поле не должно быть пустым" }]} required name="profileId">
              <Input style={{ width: 200 }} type="number" placeholder="ИД профиля" />
            </Form.Item>
            <Form.Item label="Включено" name="enabled" valuePropName="checked">
              <Switch />
            </Form.Item>
            <Form.Item label="Всегда онлайн" name="always_online" valuePropName="checked">
              <Switch />
            </Form.Item>
            <Button type="primary" htmlType="submit" icon={<PlusOutlined />}>
              Добавить
            </Button>
          </Space>
        </Form>
      </Card>
      <Form form={filter_form} onValuesChange={onValuesChange}>
        <Row gutter={16}>
          <Col span={4}>
            <Form.Item name="query">
              <Input allowClear placeholder="Поиск" />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name="country">
              <Select
                disabled={!!filter?.city}
                placeholder="Страна"
                allowClear
                showSearch
                onChange={async (value) => {
                  filter_form.setFieldValue("city", undefined);
                  await getCities(value);
                }}
                options={[
                  {
                    label: "Россия",
                    value: 1,
                  },
                  {
                    label: "РБ",
                    value: 2,
                  },
                ]}
              />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name="city">
              <Select
                placeholder="Город"
                showSearch
                allowClear
                filterOption={(text, value) => {
                  return String(value?.label || "")
                    .toLowerCase()
                    .includes(text.toLowerCase());
                }}
                options={cities.map((el) => ({ value: el.id, label: el.name }))}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Table
        loading={loading}
        rowKey="id"
        pagination={false}
        columns={columns}
        dataSource={data.data}
        scroll={{ x: 300 }}
      />
      <Pagination
        count={data.pagination.count}
        limit={data.pagination.limit}
        page={searchParams.has("page") ? Number(searchParams.get("page")) : 1}
      />
      <style>{`table td { vertical-align: top; }`}</style>
    </div>
  );
};
