import { CheckOutlined, DeleteOutlined, StopOutlined } from "@ant-design/icons";
import { Avatar, Button, Form, Input, notification, PageHeader, Popconfirm, Select, Space, Table, Tag } from "antd";
import { ColumnsType } from "antd/lib/table";
import moment from "moment/moment";
import React, { FC, Fragment, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { Pagination } from "../../components/pagination";
import { Profile } from "../../utils/Profile";
import { request } from "../../utils/request";
import cls from "./style.module.scss";

export const Ads: FC = () => {
  const [query, setQuery] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(true);

  const page = searchParams.has("page") ? Number(searchParams.get("page")) : 1;
  const order = searchParams.has("order") ? searchParams.get("order") : "moderation";

  const [data, setData] = useState({
    data: [],
    pagination: {
      count: 0,
      limit: 0,
      offset: 0,
    },
  });

  const getData = async () => {
    const params: Record<string, boolean> = {};
    const order = searchParams.has("order") ? searchParams.get("order") : "moderation";

    setLoading(true);

    if (order === "moderation") {
      params.published = false;
      params.rejected = false;
    }

    if (order === "published") {
      params.published = true;
      params.rejected = false;
    }

    if (order === "rejected") {
      params.published = false;
      params.rejected = true;
    }

    const response = await request.post("/notice/administrator/all", {
      offset: 50 * (page - 1),
      query,
      ...params,
    });
    setData(response.data);
    setLoading(false);
  };

  const remove = async (id: number) => {
    setLoading(true);

    await request.delete("/notice/administrator/remove", {
      data: { id },
    });

    await getData();
  };

  const edit = async ({ id, message, published }: { message?: string; id: number; published?: boolean }) => {
    await request.put("/notice/administrator/edit", {
      id,
      message,
      published,
    });

    await getData();

    notification.success({
      message: "Успешно сохранено",
      description: "Давайте радоваться",
    });
  };

  const search = (query: string) => {
    setQuery(query);
    setSearchParams({ page: "1", order: searchParams.get("order") || "all" });
  };

  // const startMailing = async (adId: number) => {
  //   await request.post("/notice/administrator/mailing", {
  //     adId,
  //   });
  //
  //   notification.success({
  //     message: "Ура! Сработало",
  //     description: "Рассылка запущена",
  //   });
  // };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, query, order]);

  const columns: ColumnsType<any> = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      width: 100,
    },
    {
      width: 120,
      title: "Статус",
      key: "published",
      render: (ad) => (
        <Tag color={ad.published ? "green" : ad.rejected ? "red" : "orange"}>
          {ad.published ? "Опубликовано" : ad.rejected ? "Отклонено" : "На модерации"}
        </Tag>
      ),
    },
    {
      title: "Профиль",
      dataIndex: "profile",
      key: "profileId",
      render: (profile) => {
        return (
          <a href={`/profile/${profile.id}`} rel="noreferrer">
            <Space style={{ minWidth: 200 }}>
              <Avatar size="large" src={Profile.getProfileAvatarUrl(profile)} />
              <Space direction="vertical" size={2}>
                <span>{profile.name}</span>
                <span>ID: {profile.id}</span>
              </Space>
            </Space>
          </a>
        );
      },
    },
    {
      title: "Текст",
      dataIndex: "message",
      key: "message",
      render: (_, { message, id }) => (
        <Fragment>
          <Form
            onFinish={edit}
            initialValues={{
              id,
              message,
            }}
          >
            <Form.Item hidden name="id" rules={[{ required: true }]}>
              <Input hidden />
            </Form.Item>
            <Form.Item style={{ marginBottom: 12 }} name="message" rules={[{ required: true }]}>
              <Input.TextArea className={cls.textArea} autoSize />
            </Form.Item>
            <Button type="primary" htmlType="submit" size="small">
              Сохранить
            </Button>
          </Form>
        </Fragment>
      ),
    },
    {
      title: "Отклики",
      width: 200,
      dataIndex: "id",
      key: "responses",
      render: (_, { responses }) => {
        return responses.map((item: any) => (
          <a target="_blank" key={item.profileId} href={`/profile/${item.profileId}`} rel="noreferrer">
            {item.profileId}{" "}
          </a>
        ));
      },
    },
    {
      title: "Дата создания",
      dataIndex: "createdAt",
      key: "id",
      width: 160,
      render: (_, data) => moment(data.createdAt).format("DD.MM.YYYY HH:mm"),
    },
    {
      title: "Дата редактирования",
      dataIndex: "createdAt",
      key: "id",
      width: 160,
      render: (_, data) => moment(data.updatedAt).format("DD.MM.YYYY HH:mm"),
    },
    {
      width: 230,
      title: "Управление",
      key: "action",
      render: (_, { id, published, rejected }) => (
        <Space>
          {published ? (
            <Button
              icon={<StopOutlined />}
              style={{ width: 166 }}
              onClick={() => edit({ id, published: false })}
              size="small"
              danger
              type="primary"
            >
              Снять с публикации
            </Button>
          ) : (
            <Fragment>
              <Button
                icon={<CheckOutlined />}
                type="primary"
                onClick={() => edit({ id, published: true })}
                size="small"
              >
                Опубликовать
              </Button>
              {!rejected && (
                <Button
                  icon={<StopOutlined />}
                  type="primary"
                  onClick={() => edit({ id, published: false })}
                  size="small"
                  danger
                >
                  Отклонить
                </Button>
              )}
            </Fragment>
          )}
          {/*<Button onClick={() => startMailing(id)} size="small" type="primary">*/}
          {/*  Запустить рассылку*/}
          {/*</Button>*/}
          <Popconfirm placement="leftTop" title="Удалить?" onConfirm={() => remove(id)} okText="Да" cancelText="Нет">
            <Button size="small" danger={true} icon={<DeleteOutlined />} />
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <div>
      <PageHeader ghost={false} title="Объявления" />
      <Space style={{ marginBottom: 24 }}>
        <Input.Search
          style={{ width: 400 }}
          placeholder="Поиск: (ИД объявления, ИД пользователя, текст объявления)"
          onSearch={search}
        />
        <Select
          style={{ width: 200 }}
          placeholder="Статус"
          allowClear={true}
          defaultValue="moderation"
          options={[
            { label: "Все", value: "all" },
            { label: "На модерации", value: "moderation" },
            { label: "Одобрено", value: "published" },
            { label: "Отклонено", value: "rejected" },
          ]}
          onChange={(order) => {
            setSearchParams({ order, page: "1" });
          }}
        />
      </Space>

      <Table
        loading={loading}
        rowKey="id"
        pagination={false}
        columns={columns}
        dataSource={data.data}
        scroll={{ x: 300 }}
      />

      <Pagination
        count={data.pagination.count}
        limit={data.pagination.limit}
        page={searchParams.has("page") ? Number(searchParams.get("page")) : 1}
      />
    </div>
  );
};
