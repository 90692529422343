import { DeleteOutlined } from "@ant-design/icons";
import { Avatar, Button, PageHeader, Popconfirm, Space, Table, Tag } from "antd";
import { ColumnsType } from "antd/lib/table";
import moment from "moment/moment";
import React, { FC, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { Pagination } from "../../components/pagination";
import { domains } from "../../contastants/domains";
import { Profile } from "../../utils/Profile";
import { request } from "../../utils/request";

export const Tickets: FC = () => {
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(true);

  const page = searchParams.has("page") ? Number(searchParams.get("page")) : 1;

  const [data, setData] = useState({
    data: [],
    pagination: {
      count: 0,
      limit: 0,
      offset: 0,
    },
  });

  const getData = async () => {
    setLoading(true);

    const response = await request.post("/tickets/all", {
      offset: 50 * (page - 1),
    });
    setData(response.data);
    setLoading(false);
  };

  const remove = async (id: number) => {
    setLoading(true);
    await request.post("/tickets/delete", { id });
    await getData();
  };

  useEffect(() => {
    getData();
  }, [page]);

  const columns: ColumnsType<any> = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      width: 50,
    },
    {
      title: "Домен",
      dataIndex: "domain",
      render: (domain) => domains[domain],
    },
    {
      width: 100,
      title: "Профиль",
      dataIndex: "profile",
      key: "profileId",
      render: (profile) => {
        if (!profile) {
          return <Tag>Не авторизован</Tag>;
        }

        return (
          <a href={`/profile/${profile.id}`} rel="noreferrer">
            <Space style={{ minWidth: 200 }}>
              <Avatar size="large" src={Profile.getProfileAvatarUrl(profile)} />
              <Space direction="vertical" size={2}>
                <span>{profile.name}</span>
                <span>ID: {profile.id}</span>
              </Space>
            </Space>
          </a>
        );
      },
    },
    {
      title: "E-mail",
      dataIndex: "email",
      key: "id",
    },
    {
      title: "Сообщение",
      dataIndex: "message",
      key: "message",
    },
    {
      title: "Дата создания",
      dataIndex: "createdAt",
      key: "id",
      width: 160,
      render: (_, data) => moment(data.createdAt).format("DD.MM.YYYY HH:mm"),
    },
    {
      width: 230,
      title: "Управление",
      key: "action",
      render: (_, { id }) => (
        <Popconfirm placement="leftTop" title="Удалить?" onConfirm={() => remove(id)} okText="Да" cancelText="Нет">
          <Button size="small" danger={true} icon={<DeleteOutlined />}>
            Удалить
          </Button>
        </Popconfirm>
      ),
    },
  ];

  return (
    <>
      <PageHeader ghost={false} title="Тикеты" />
      <Table
        loading={loading}
        rowKey="id"
        pagination={false}
        columns={columns}
        dataSource={data.data}
        scroll={{ x: 300 }}
      />
      <Pagination
        count={data.pagination.count}
        limit={data.pagination.limit}
        page={searchParams.has("page") ? Number(searchParams.get("page")) : 1}
      />
    </>
  );
};
