import { Input, PageHeader, Table, Tag } from "antd";
import { ColumnsType } from "antd/lib/table";
import moment from "moment";
import { FC, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { Pagination } from "../../components/pagination";
import { IUser } from "../../interfaces/user";
import { request } from "../../utils/request";

export const Users: FC = () => {
  const [query, setQuery] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const page = searchParams.has("page") ? Number(searchParams.get("page")) : 1;
  const [loading, setLoading] = useState(true);

  const [users, setUsers] = useState({
    data: [],
    pagination: {
      count: 0,
      limit: 0,
      offset: 0,
    },
  });

  const getUsers = async () => {
    const response = await request.post("/users/all", {
      offset: 50 * (page - 1),
      query,
    });
    setUsers(response.data);
    setLoading(false);
  };

  const subscribeToggle = async (email: string, subscribed: boolean) => {
    setLoading(true);

    await request.post("/mail/subscribe-administrator", {
      email,
      subscribed: !subscribed,
    });

    await getUsers();
  };

  const search = (query: string) => {
    setQuery(query);
    setSearchParams({ page: "1" });
  };

  useEffect(() => {
    getUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, query]);

  const columns: ColumnsType<IUser> = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Дата",
      dataIndex: "createdAt",
      key: "id",
      render: (_, { createdAt }) => moment(createdAt).format("DD.MM.YYYY HH:mm"),
    },
    {
      title: "Почта",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Роль",
      dataIndex: "role",
      key: "role",
      render: (_, { roles }) =>
        roles.map(({ id, value, description }) => (
          <Tag color={value === "user" ? "default" : value === "delete" ? "red" : "green"} key={id}>
            {description}
          </Tag>
        )),
    },
    {
      title: "Комментарий",
      dataIndex: "comment",
      key: "comment",
    },
  ];

  return (
    <div>
      <PageHeader ghost={false} title="Пользователи" />

      <Input.Search size="large" style={{ width: "100%", marginBottom: 16 }} placeholder="Поиск" onSearch={search} />

      <Table
        loading={loading}
        rowKey="id"
        scroll={{ x: 300 }}
        pagination={false}
        columns={columns}
        dataSource={users.data}
      />

      <Pagination
        count={users.pagination.count}
        limit={users.pagination.limit}
        page={searchParams.has("page") ? Number(searchParams.get("page")) : 1}
      />
    </div>
  );
};
