import "./index.css";

import { ConfigProvider } from "antd";
import ru from "antd/es/locale/ru_RU";
import cookie from "cookiejs";
import React, { FC, useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";
import { Bank } from "routes/bank";

import { Login } from "./components/login";
import { Spinner } from "./components/spinner";
import { Wrapper } from "./components/wrapper";
import { IRole, IUser } from "./interfaces/user";
import { Activity } from "./routes/activity";
import { Ads } from "./routes/ads";
import { Charts } from "./routes/charts";
import { ChatMessages } from "./routes/chat-messages";
import { Chats } from "./routes/chats";
import { Complaints } from "./routes/complaint";
import { Confirmations } from "./routes/confirmations";
import { Dashboard } from "./routes/dashboard";
import { Devices } from "./routes/devices";
import { Expenses } from "./routes/expenses";
import { ListPhoto } from "./routes/list-photo";
import { Messages } from "./routes/messages";
import { NotFound } from "./routes/not-found";
import { Pages } from "./routes/pages";
import { PagesManage } from "./routes/pages-manage";
import { PhotoModeration } from "./routes/photo-moderation";
import { Profile } from "./routes/profile";
import { Profiles } from "./routes/profiles";
import { Promotions } from "./routes/promotions";
import { Ratings } from "./routes/ratings";
import { RatingsPage } from "./routes/ratings-page";
import { RatingsPages } from "./routes/ratings-pages";
import { RatingsProfile } from "./routes/ratings-profile";
import { RatingsProfiles } from "./routes/ratings-profiles";
import { RatingsReview } from "./routes/ratings-review";
import { RatingsReviews } from "./routes/ratings-reviews";
import { RatingsSite } from "./routes/ratings-site";
import { RatingsSites } from "./routes/ratings-sites";
import { Tickets } from "./routes/tickets";
import { Transactions } from "./routes/transactions";
import { Users } from "./routes/users";
import { request } from "./utils/request";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

interface IContent {
  setBasename: React.Dispatch<React.SetStateAction<string>>;
  basename: string;
}

const Amorus: FC<IContent> = ({ setBasename, basename }) => {
  const [authorized, setAuthorized] = useState(false);
  const [loading, setLoading] = useState(true);
  const [role, setRole] = useState<IRole["value"]>("user");
  const location = useLocation();
  useEffect(() => {
    if (basename !== "/lovers" && location.pathname.startsWith("/lovers")) {
      setBasename("/lovers");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const auth = async () => {
    const token = cookie.get("cp_token");

    try {
      if (token) {
        const result = await request.get("/users");

        const user = result.data as IUser;

        if (user.roles.some(({ value }) => value === "moderator")) {
          setRole("moderator");
          setAuthorized(true);
        }

        if (user.roles.some(({ value }) => value === "administrator")) {
          setRole("administrator");
          setAuthorized(true);
        }
      }
    } catch (err) {
      cookie.clear("cp_token");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    auth();
  }, []);

  if (loading) {
    return <Spinner />;
  }

  if (authorized) {
    return (
      <Wrapper role={role}>
        <Routes>
          <Route path="/" element={role === "administrator" ? <Dashboard /> : <PhotoModeration />} />
          <Route path="/expenses" element={<Expenses />} />
          <Route path="/bank" element={<Bank />} />
          <Route path="/charts" element={<Charts />} />
          <Route path="/users" element={<Users />} />
          <Route path="/devices" element={<Devices />} />
          <Route path="/ads" element={<Ads />} />
          <Route path="/promotions" element={<Promotions />} />
          <Route path="/activity" element={<Activity />} />
          <Route path="/chats/messages/:chatId" element={<ChatMessages />} />
          <Route path="/chats/:profileId" element={<Chats />} />
          <Route path="/messages" element={<Messages />} />
          <Route path="/list-photo" element={<ListPhoto />} />
          <Route path="/transactions" element={<Transactions />} />
          <Route path="/confirmations" element={<Confirmations />} />
          <Route path="/complaints" element={<Complaints />} />
          <Route path="/photo-moderation" element={<PhotoModeration />} />
          <Route path="/profile/:id" element={<Profile />} />
          <Route path="/profiles" element={<Profiles />} />
          <Route path="/tickets" element={<Tickets />} />

          <Route path="/pages" element={<Pages />} />
          <Route path="/pages/manage" element={<PagesManage />} />
          <Route path="/pages/manage/:id" element={<PagesManage />} />

          <Route path="/ratings" element={<Ratings />} />
          <Route path="/ratings/:source" element={<RatingsSites />} />

          <Route path="/ratings-pages/:source" element={<RatingsPages />} />
          <Route path="/ratings-page/:source" element={<RatingsPage />} />
          <Route path="/ratings-page/:source/:id" element={<RatingsPage />} />

          <Route path="/ratings-profiles/:site_id" element={<RatingsProfiles />} />
          <Route path="/ratings-profile/:site_id" element={<RatingsProfile />} />
          <Route path="/ratings-profile/:site_id/:id" element={<RatingsProfile />} />

          <Route path="/ratings-reviews/:site_id" element={<RatingsReviews />} />
          <Route path="/ratings-review/:site_id" element={<RatingsReview />} />
          <Route path="/ratings-review/:site_id/:id" element={<RatingsReview />} />

          <Route path="/ratings-site/:source" element={<RatingsSite />} />
          <Route path="/ratings-site/:source/:id" element={<RatingsSite />} />

          <Route path="*" element={<NotFound />} />
        </Routes>
      </Wrapper>
    );
  }
  return <Login />;
};

const Navigation: FC = () => {
  const [basename, setBasename] = useState(
    window.location.pathname.startsWith("/lovers") ? "/lovers" : process.env.PUBLIC_URL,
  );
  return (
    <ConfigProvider locale={ru}>
      <Router key={basename} basename={basename}>
        <Amorus basename={basename} setBasename={setBasename} />
      </Router>
    </ConfigProvider>
  );
};

root.render(<Navigation />);
